import React from 'react';

import Yc from '../../assets/ycombinator.png';
import Sequoia from '../../assets/sequoia.svg';
import Accel from '../../assets/accel.svg';
import Index from '../../assets/index.svg';
import Venrock from '../../assets/venrock.svg';
import Firstround from '../../assets/firstround.svg';
import Lighstpeed from '../../assets/lightspeed.svg';
import Andreessen from '../../assets/andreessen.svg';
import Svangel from '../../assets/svangel.svg';
import Bain from '../../assets/baincapital.svg';

const HowItWorks = () => (
  <section>
    <div className="py-12 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-4 lg:max-w-screen-xl lg:px-8">
        <div className="text-center mx-auto mb-16">
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            How it works
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
          Getting started with Crowdcreate only takes a few seconds!
          </p>
        </div>
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="shadow-sm rounded-lg p-4 border border-gray-200">
            <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-red-600 text-white">
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-bold text-gray-900">1. Find relevant investors & influencers</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Search through our constantly evolving database of contacts categorized by industry and what they’re most likely to get involved with next
              </p>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 shadow-sm rounded-lg p-4 border border-gray-200">
            <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-red-600 text-white">
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.707 7.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L13 8.586V5h3a2 2 0 012 2v5a2 2 0 01-2 2H8a2 2 0 01-2-2V7a2 2 0 012-2h3v3.586L9.707 7.293zM11 3a1 1 0 112 0v2h-2V3z" />
                <path d="M4 9a2 2 0 00-2 2v5a2 2 0 002 2h8a2 2 0 002-2H4V9z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-bold text-gray-900">2. Save your favorite contacts to your own customized lists
              </h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Save the best investors & influencers to our list so you can pitch them your business and collaborate together
              </p>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 shadow-sm rounded-lg p-4 border border-gray-200">
            <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-red-600 text-white">
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-bold text-gray-900">3. Start pitching your business</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Get connected with these investors & influencers. Introduce yourself so you can start working together, it’s a win-win situation for you and them
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={Sequoia} alt="Sequoia Capital" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={Lighstpeed} alt="Lightspeed Venture Partners" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={Andreessen} alt="Andreessen Horowitz" />
          </div>  
          <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
            <img className="h-12" src={Venrock} alt="Venrock" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img className="h-12" src={Index} alt="Index Ventures" />
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white pb-12">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={Firstround} alt="First Round Capital" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={Yc} alt="Y Combinator" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <img className="h-12" src={Accel} alt="Accel Partners" />
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <img className="h-12" src={Svangel} alt="SV Angel Group" />
          </div>
          <div className="col-span-2 flex justify-center md:col-span-3 lg:col-span-1">
            <img className="h-12" src={Bain} alt="Bain Capital" />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default HowItWorks;
