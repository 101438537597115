import React from 'react';
import { NavLink } from 'react-router-dom';
import HookSignUpModule from './hookSignUpModule';

import Logo from '../../assets/crowdcreate-logo.svg';

export default function HookSignUp(props) {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
        <NavLink to="/">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="InvestorScout" />
        </NavLink>
        <h2 className="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
          Sign up with
        </h2>
        <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
          <span>Already have an account? </span>          
          <NavLink to="/signin" className="font-medium text-blue-1000 hover:text-blue-500 focus:outline-none focus:underline transition ease-in-out duration-150">
            sign in now
          </NavLink>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <HookSignUpModule props={props} />
        </div>
      </div>
    </div>
  );
}
