import React from 'react';
import { NavLink } from 'react-router-dom';

const Footer = () => (
  <div className="bg-white border-t border-gray-200">
    <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav className="-mx-5 -my-2 flex flex-wrap justify-center">
        <div className="px-5 py-2">
          <NavLink to="/" className="text-base leading-6 text-gray-500 hover:text-gray-900">
            Home
          </NavLink>
        </div>
        <div className="px-5 py-2">
          <NavLink to="/pricing" className="text-base leading-6 text-gray-500 hover:text-gray-900">
            Pricing
          </NavLink>
        </div>
        <div className="px-5 py-2">
          <a href="https://crowdcreate.us/terms-conditions/" className="text-base leading-6 text-gray-500 hover:text-gray-900">
            Terms & Conditions
          </a>
        </div>
      </nav>
      <div className="mt-8">
        <p className="text-center text-base leading-6 text-gray-400">
          &copy; {new Date().getFullYear()} Crowdcreate. All rights reserved.<br /><br />
        </p>
      </div>
    </div>
  </div>
)

export default Footer;
