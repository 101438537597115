import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
} from 'react-instantsearch-dom';
import ListHookForm from './listHookForm';
import searchClient from './algoliaCred';
import Transition from '../../styles/Transition.js';

import InvestorFilters from './investorFilters';
import InvestorResult from '../search/investorResult';
import CustomHitsPerPage from './customHitsPerPage';
import CustomHits from './customHits';

class InvestorDash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: this.props.subscription,
      status: this.props.status,
      plan: this.props.plan,
      lists: this.props.lists,
      modalOn: false,
    };
    this.activateModal = this.activateModal.bind(this);
  }

  activateModal() {
    this.setState((prevState) => ({
      modalOn: !prevState.modalOn,
    }));
  }

  render() {
    const { modalOn, plan, status } = this.state;
    return (
      <div>
        <div className={`${modalOn ? 'block z-10' : 'hidden'} fixed inset-0 overflow-y-auto`}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition
              show={modalOn}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75" />
              </div>
            </Transition>

            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" />
            &#8203;
            <Transition
              show={modalOn}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <ListHookForm activateModal={this.activateModal} fromDash />
            </Transition>
          </div>
        </div>
        <InstantSearch
          searchClient={searchClient}
          indexName="crowdcreate_data_v1"
        >
          <div className="max-w-7xl py-10 mx-4 lg:mx-40 px-4 sm:px-0 lg:px-0">
            <div className="pb-5 space-y-3 sm:block md:flex md:justify-end sm:space-x-4 sm:space-y-0">
              { (status === 'active' && (plan === 3 || plan === 4)) ? (
                <button onClick={this.activateModal} type="button" className="mr-0 text-base bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
                  <svg className="opacity-50 fill-current outline-current mr-2 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11h4m-2-2v4" />
                  </svg>
                  <span>New list</span>
                </button>
              ) : (
                <NavLink to="/pricing" className="mr-0 text-base bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
                  <svg className="opacity-50 fill-current outline-current mr-2 h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                    <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 11h4m-2-2v4" />
                  </svg>
                  <span>New list</span>
                  <svg className="ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#ef2942">
                    <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
                  </svg>
                </NavLink>
              ) }
              { (status === 'active' && plan === 4) ? (
                <CustomHitsPerPage
                  defaultRefinement={20}
                  items={[
	                  { value: 20, label: 'Show 20 contacts' },
	                  { value: 50, label: 'Show 50 contacts' },
	                  { value: 100, label: 'Show 100 contacts' },
	                ]}
                />
					  	) : (status === 'active' && plan !== 4) ? (
  <CustomHitsPerPage
    defaultRefinement={20}
    items={[
	                  { value: 20, label: 'Show 20 contacts' },
	                  { value: 50, label: 'Show 50 contacts' },
	                ]}
  />
					  	) : (
  <CustomHitsPerPage
    defaultRefinement={3}
    items={[
	                  { value: 3, label: 'Show 3 contacts' },
	                ]}
  />
					  	) }
              {(plan === 2 || plan === 20 || plan === 3 || plan === 30 || plan === 4 || plan === 40) ? (
                <Pagination
                  className="text-base bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
                  translations={{
                    previous: 'Back',
                    next: 'Next',
                  }}
                  padding={0}
                  showLast={false}
                  showFirst={false}
                />
              ) : <span style={{ display: 'none' }} />}
              {/* this.props.lists.length > 0 ? (
              	<CustomHits
              		lists={this.props.lists}
              		status={status}
              		plan={plan}
              		dataType="investor"
              	/>
              ) : <span /> */}
            </div>
            <div className="grid grid-cols-6 gap-4">
              <div className="col-span-6 sm:col-span-6 md:col-span-2 lg:col-span-2">
                <InvestorFilters />
              </div>
              <div className="col-span-6 sm:col-span-6 md:col-span-4 lg:col-span-4">
                <SearchBox
                  translations={{ placeholder: '🔎  Search through investors…' }}
                  className="mb-6"
                  submit={<img src="/submit.png" alt="" />}
                  focusShortcuts={['s']}
                />
                <Hits hitComponent={({ hit }) => (<InvestorResult hit={hit} lists={this.props.lists} status={status} plan={plan} />)} />
              </div>

              { status === 'active' ? (
                <div className="col-span-6">
                  <Pagination
                    className="bg-white rounded-lg shadow-sm p-3 w-64 float-right text-center text-base font-semibold border border-gray-200"
                    translations={{
		                  previous: 'Back',
		                  next: 'Next',
		                }}
                    padding={2}
                    showLast={false}
                    showFirst={false}
                  />
                </div>
		          ) : <span /> }
            </div>
          </div>
        </InstantSearch>
      </div>
    );
  }
}

export default InvestorDash;
