import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Linkedin from '../../assets/linkedin-dark.svg';
import Website from '../../assets/website.png';
import Youtube from '../../assets/youtube.png';
import Instagram from '../../assets/instagram.png';
import Twitter from '../../assets/twitter.png';

export default function SocialLinks({ hit }) {

  return (
	  <div className="flex">
	    {hit["Personal Website"] ? (
	    	<div className="ml-4 mt-4 flex-shrink-0 w-8 h-8">
	        <a href={hit["Personal Website"]} target="_blank" rel="noopener noreferrer">
            <img src={Website} />
          </a>
		    </div>
		  ) : <span /> }
	    {hit["Personal LinkedIn URL"] ? (
	    	<div className="ml-4 mt-4 flex-shrink-0 w-8 h-8">
	        <a href={hit["Personal LinkedIn URL"]} target="_blank" rel="noopener noreferrer">
            <img src={Linkedin} />
          </a>
		    </div>
		  ) : <span /> }
	    {hit["Twitter URL"] ? (
	    	<div className="ml-4 mt-4 flex-shrink-0 w-8 h-8">
	        <a href={hit["Twitter URL"]} target="_blank" rel="noopener noreferrer">
            <img src={Twitter} />
          </a>
		    </div>
		  ) : <span /> }
	    {hit["Youtube URL"] ? (
	    	<div className="ml-4 mt-4 flex-shrink-0 w-8 h-8">
	        <a href={hit["Youtube URL"]} target="_blank" rel="noopener noreferrer">
            <img src={Youtube} />
          </a>
		    </div>
		  ) : <span /> }
	    {hit["Instagram URL"] ? (
	    	<div className="ml-4 mt-4 flex-shrink-0 w-8 h-8">
	        <a href={hit["Instagram URL"]} target="_blank" rel="noopener noreferrer">
            <img src={Instagram} />
          </a>
		    </div>
		  ) : <span /> }
		</div>
	);
}
