import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import OutsideClickHandler from 'react-outside-click-handler';
import Transition from '../../styles/Transition.js';
import AddToListButton from './addToListButton';
import SocialLinks from './socialLinks';
import Linkedin from '../../assets/linkedin-dark.svg'; 

export default function InvestorResults({ hit, lists, status, plan }) {

  const [infoDropdownOn, setInfoDropdown] = useState(false);
	const [contactDropdownOn, setContactDropdown] = useState(false);

  return (
		<div className="bg-white mb-10 overflow-hidden sm:rounded-lg shadow-sm border border-gray-200">
			<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 mb-6">
			  <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
			    <div className="ml-4 mt-4">
				    <h3 className="text-lg leading-6 font-bold text-gray-900">
				      {hit["First Name"]} {hit["Last Name"]}
				    </h3>
				    <div className="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
				      {hit["Job Title"]} {hit["Company Website"] ? "at" : ""} <a href={hit["Company Website"]} target="_blank" rel="noopener noreferrer"><span className="font-medium">{hit["Company Name"]}</span></a>
				    </div>
				    <div className="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
				      {hit["City"]}{hit["City"] ? "," : ""} {hit["State"]}{hit["State"] ? "," : ""} {hit["Country"]}
				    </div>
			    </div>
			    <div className="relative">
				    <div className="ml-4 flex-shrink-0">
			        <button type="button" onClick={() => setInfoDropdown(!infoDropdownOn)} className="text-sm bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center">
                <svg className="opacity-50 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                </svg>
                Contact
				        <svg className="opacity-50 ml-2 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
				          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
				        </svg>
			        </button>
				    </div>
		        <Transition
		          show={infoDropdownOn}
		          enter="transition ease-out duration-200"
		          enterFrom="transform opacity-0 scale-95"
		          enterTo="transform opacity-100 scale-100"
		          leave="transition ease-in duration-75"
		          leaveFrom="transform opacity-100 scale-100"
		          leaveTo="transform opacity-0 scale-95"
		        >
						  <div className={`${infoDropdownOn ? 'block' : 'hidden'} origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg`}>
						    <div className="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
						      <div className="px-4 py-3">
						        <div className="text-sm leading-5 text-gray-600">
						          Email Address
						        </div>
						        {status === "active" ? (
							        <div className="text-sm leading-5 font-medium">
							        	{hit["Email Address"]}
							        </div>
						        ) : (
							        <NavLink to="/pricing" className="underline text-sm leading-5 font-medium">
							        	Upgrade to view
							        </NavLink>
						        ) }
						      </div>
						    </div>
						  </div>
						</Transition>
					</div>
			  </div>
			</div>

		  <div className="px-4 py-2 mb-2 sm:px-6">
		    <dl className="grid grid-cols-1 col-gap-4 row-gap-6 sm:grid-cols-3">
        {hit["Industry Type"] ? (
          <div className="sm:col-span-1">
            <dt className="text-sm leading-5 font-normal text-gray-500">
              Industry Type
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900">
            {(hit["Industry Type"] && Array.isArray(hit["Industry Type"])) ? (
	        		<span>{hit["Industry Type"].join(', ')}</span>
	        	) : <span>{hit["Industry Type"]}</span> }
            </dd>
          </div>
        ) : <span /> }
        {hit["Business Type"] ? (
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-normal text-gray-500">
		          Business Type
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit["Business Type"] && Array.isArray(hit["Business Type"])) ? (
	        		<span>{hit["Business Type"].join(', ')}</span>
	        	) : <span>{hit["Business Type"]}</span> }
	        	</dd>
		      </div>
        ) : <span /> }
        {hit["Keywords"] ? (
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-normal text-gray-500">
		          Keywords
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit["Keywords"] && Array.isArray(hit["Keywords"])) ? (
	        		<span>{hit["Keywords"].join(', ')}</span>
	        	) : <span>{hit["Keywords"]}</span> }
	        	</dd>
		      </div>
        ) : <span /> }
		    </dl>
		  </div>
		  <div className="px-4 py-2 mb-2 sm:px-6">
		    <dl className="grid grid-cols-1 col-gap-4 row-gap-6 sm:grid-cols-1">
        {hit["Bio"] ? (
          <div className="sm:col-span-1">
            <dt className="text-sm leading-5 font-normal text-gray-500">
              Bio
            </dt>
            <dd className="mt-1 text-sm leading-5 text-gray-900">
	        		<span>{hit["Bio"]}</span>
            </dd>
          </div>
        ) : <span /> }
       	</dl>
      </div>
			<div className="bg-white px-4 py-6 border-t mt-4 border-gray-200 sm:px-6">
			  <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
			  	<div>
				  	<SocialLinks hit={hit} />
			  	</div>
					<div>
						{(lists.length > 0 && status === "active") ? (
							<AddToListButton lists={lists} profile={hit} plan={plan} status={status} dataType="investor" />
						) : (lists.length < 1 && status === "active") ? (
							<div className="ml-4 mt-4 flex-shrink-0">
								<NavLink
		              to="lists"
		              type="button"
		              className="ml-3 text-sm bg-gray-800 hover:opacity-75 text-white font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
		            >
		              <svg className="opacity-50 mr-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
		              </svg>
		              No list yet
		              <svg className="opacity-50 ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
		              </svg>
		            </NavLink>
		          </div>
		        ) : (
							<div className="ml-4 mt-4 flex-shrink-0">
								<NavLink
		              to="/pricing"
		              type="button"
		              className="ml-3 text-sm bg-gray-800 hover:opacity-75 text-white font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
		            >
		              <svg className="opacity-50 mr-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
		              </svg>
		              Save
		              <svg className="opacity-50 ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
		                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
		              </svg>
		            </NavLink>
		          </div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
