import React from 'react';
import { NavLink } from 'react-router-dom';

import LandingNav from './nav';
import HookSignUpModule from '../account/hookSignUpModule';

import Anker from '../../assets/anker-logo.png';
import StarAtlas from '../../assets/star-atlas-logo.svg';
import Cypherium from '../../assets/cypherium-logo.png';

export default function Hero({ props }) {

  return (
    <div className="relative bg-gray-900 overflow-hidden">
      <div className="hidden sm:block sm:absolute sm:inset-0">
        <svg className="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0" width="364" height="384" viewBox="0 0 364 384" fill="none">
          <defs>
            <pattern id="eab71dd9-9d7a-47bd-8044-256344ee00d0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="364" height="384" fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
        </svg>
      </div>
      <div className="relative pt-6 pb-12 sm:pb-32">
        <LandingNav />
        <main className="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
          <div className="mx-auto max-w-screen-xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <div>
                  <h2 className="mt-4 text-4xl tracking-tight leading-10 font-extrabold text-red-600
                   sm:mt-5 sm:leading-none sm:text-6xl lg:mt-6 lg:text-5xl xl:text-6xl">
                    Connect with the best investors & influencers to <span className="text-white">grow your business in seconds</span>
                  </h2>
                  <p className="mt-3 text-base text-gray-100 text-opacity-50 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    We’ve spent hundreds of thousands of dollars building this proprietary database, now we’re giving you instant access. Save yourself hundreds of hours of manual research and tap into our data on the top investors and influencers in your exact industry complete with contact information
                  </p>
                  <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">Used by</p>
                  <div className="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                    <div className="flex flex-wrap items-start justify-between">
                      <div className="flex justify-center px-1 pt-8">
                        <img className="h-5 sm:h-5" src={Anker} alt="Anker" />
                      </div>
                      <div className="flex justify-center px-1 pt-8">
                        <img className="h-5 sm:h-5" src={StarAtlas} alt="StarAtlas" />
                      </div>
                      <div className="flex justify-center px-1 pt-5">
                        <img className="h-10 sm:h-10" src={Cypherium} alt="Cypherium" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
                <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <div>
                      <p className="text-sm leading-5 font-medium text-gray-700">
                        Sign up for Crowdcreate today
                      </p>
                    </div>
                    <div className="mt-6">
                      <HookSignUpModule props={props} />               
                    </div>
                  </div>
                  <div className="px-4 py-6 bg-gray-50 border-t border-gray-200 sm:px-10">
                    <p className="text-xs leading-5 text-gray-500">By signing up, you agree to our <a href="https://crowdcreate.us/terms-conditions/" className="font-medium text-gray-900 hover:underline">Terms & Conditions</a> and <a href="https://www.iubenda.com/privacy-policy/26558236/cookie-policy" className="font-medium text-gray-900 hover:underline">Cookies Policy</a>.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
