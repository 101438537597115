import React from 'react';
import { NavLink } from 'react-router-dom';

const Faq = () => (
  <div className="bg-white" id="FAQ">
    <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
      <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
        Frequently asked questions
      </h2>
      <div className="mt-6 border-t border-gray-200 pt-10">
        <dl className="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What kind of investors & influencers do you have in this database?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Crowdcreate has compiled tens of thousands of investors and influencers across multiple industries with a core focus on crypto, startups, ecommerce, and real estate. Investors include accredited investors, angel investors, VC investors, angel investors, private equity investors, and more. Influencers include expert thought leaders, YouTube influencers, Twitter influencers, key opinion leaders (KOL), conference speakers, and more. From the USA and Asia, to Europe and the Middle East, our contact database spans multiple countries.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What do I do with these emails?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  It’s your choice. Our recommendation after years of sending hundreds of thousands of emails? Reach out to them in a value driven, insightful, and genuine way. This is your opportunity to get in front of the most influential people in your industry. Whether you’re looking to raise capital, get featured on their channel, or setup a strategic partnership, the possibilities are endless.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How do I know these emails are accurate?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Our database references multiple sources including social media websites such as LinkedIn, 3rd party email validators, public databases, and proprietary in-house technology. We use advanced web scraping tools, as well as human verified processes that validate contacts to be the best possible data.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How often is this data updated?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  All the time. We leverage dozens of authoritative data sources and maintain our own proprietary technology to bring you the best contact information possible. Some industries are updated more frequently than others, and will vary depending how fast the industry is moving. Improvements are constantly being made.
                </p>
              </dd>
            </div>
          </div>
          <div className="mt-12 md:mt-0">
            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Why can’t I see the emails?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Our basic package doesn’t come with access to emails. If you’re on a plan that includes emails, and you don’t see them, it is likely because we don’t have emails on record for that contact. This can be due to many reasons, from GDPR or privacy regulations to them not having their email publicly accessible.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How did you get this data?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Our own proprietary research and dozens of authoritative data sources. From industry trade show conferences and LinkedIn profiles, to YouTube profiles and public new sources, our team of expert analysts are constantly optimizing our data sets based on rapid iterative feedback from industry insights.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How do I contact these investors?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Follow best practices and do not spam these contacts. You risk blacklisting your domain and doing more harm than good if you are unsure. Follow all GDPR and privacy regulations. Give value, introduce yourself like you would in real life, and understand there is a person on the other end of your message. Crowdcreate offers a full-service outreach if you also need help.
                </p>
              </dd>
            </div>
            <div className="mt-12">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How do I work with these people?
              </dt>
              <dd className="mt-2">
                <p className="text-base leading-6 text-gray-500">
                  Raising capital, paying for exposure & sponsorships, setting up strategic partnerships, getting introductions to other founders, asking for advice, conducting focus group surveys, building your user base, and acquiring more customers are just some of the ways our customers use our databases.
                </p>
              </dd>
            </div>
          </div>
        </dl>
      </div>
    </div>
    <div className="bg-gray-100 border-t border-gray-200">
      <div className="max-w-screen-xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
          Ready to get connected?
          <br />
          Get started for free today.
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-lg shadow-sm">
            <NavLink to="/signup" className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-bold rounded-lg text-white bg-red-600 hover:opacity-75 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
              Get started for free
              <svg className="fill-current opacity-50 w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
              </svg>
            </NavLink>
          </div>
          <div className="ml-3 inline-flex rounded-lg">
            <NavLink to="/pricing" className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-bold rounded-lg shadow-sm border border-gray-200 text-gray-900 bg-white focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out">
              See pricing
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Faq;
