import React, { Component } from 'react';
import { CSVLink } from 'react-csv';

class Csv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvArr: [],
    };
  }

  buildCsv() {
    console.log("Build CSV Triggered!");

    const entries = this.props.entries;
    const plan = this.props.plan;

    var entryArr = [];
    var csvArr = [ ['Name', 'Title', 'Firm','Email', "Website", "LinkedIn", "Investor Type"], ];
      Object.keys(entries).map(key => {
        let entry = entries[key].data();
        return entryArr.push(
          entry["First Name"] + " " + entry["Last Name"],
          entry["Job Title"],
          entry["Company Name"],
          entry["Email Address"],
          entry["Company Website"],
          entry["Personal LinkedIn URL"],
          entry["Industry Type"]
        ),
        csvArr.push(entryArr),
        entryArr = [];
      })
    this.setState({ csvArr: csvArr, });
  }

  render() {
    const { csvArr } = this.state;
    return (
      <CSVLink
        filename={"investor-scout-contact-list.csv"}
        onClick={this.buildCsv.bind(this)}
        data={csvArr}
        className="mr-0 ml-3 text-base bg-white hover:text-gray-900 text-gray-700 font-semibold py-2 px-4 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
        target='_self'
      >
        <svg className="opacity-50 fill-current outline-current h-5 w-5 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
          <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 9v4m0 0l-2-2m2 2l2-2" />
        </svg>
        <span className="ml-2">Download CSV</span>
      </CSVLink>
    );
  }
}

export default Csv;
