import React from 'react';
import { NavLink } from 'react-router-dom';

export default function InvestorResults({ hit, plan }) {

  return (
		<div className="bg-white mb-10 overflow-hidden sm:rounded-lg sm:mx-40 border border-gray-200 shadow-sm">
			<div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
			  <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
			    <div className="ml-4 mt-4">
				    <h3 className="text-lg leading-6 font-medium text-gray-900">
				      {hit["First Name"]} {hit["Last Name"]}
				    </h3>
				    <div className="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
				      {hit["Job Title"]} {hit["Company Website"] ? "at" : ""} <a href={hit["Company Website"]} target="_blank" rel="noopener noreferrer"><span className="font-medium">{hit["Company Name"]}</span></a>
				    </div>
				    <div className="mt-1 max-w-2xl text-sm leading-5 text-gray-600">
				      {hit["City"]}{hit["City"] ? "," : ""} {hit["State"]}{hit["State"] ? "," : ""} {hit["Country"]}
				    </div>
			    </div>
			  </div>
			</div>

			{/*<div className="px-4 py-5 sm:px-6">
		    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          About
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		          <span>{hit.numberOfOffices} office(s)</span><br />
		          <span>{hit.numberOfInvestors} investor(s)</span><br />
		          <span>founded in {hit.yearFounded}</span><br />
		          <span>{hit.genderRatio}</span>
		        </dd>
		      </div>
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Financing Type
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
	        	{(hit.typeOfFinancing && Array.isArray(hit.typeOfFinancing)) ? (
	        		<span>{hit.typeOfFinancing.join(', ')}</span>
	        	) : <span /> }
		        </dd>
		      </div>
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Headquarters
		        </dt>
		        {(plan === 3 || plan === 30 || plan === 4) ? (
			        <dd className="mt-1 text-sm leading-5 text-gray-900">
			          <span>{hit.officeAddress1}</span><br />
			          {hit.officeAddress2 ? (<p><span>{hit.officeAddress2}</span><br /></p>) : <span />}
			          <span>{hit.officeCity}, </span>
			          <span>{hit.officeState}, </span>
			          <span>{hit.officeZip}</span><br />
			          <span>{hit.officeCountry}</span>
			        </dd>
			      ) : (
			      	<NavLink to="/pricing" className="text-sm leading-5 font-medium underline">
			      		<span>Upgrade to access</span>
			      	</NavLink>
			      ) }
		      </div>
		    </dl>
		  </div>

      <div>
        <div className="mx-4 mt-0 mb-6 sm:mx-6 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow-sm border border-gray-200 md:grid-cols-3">
          <div>
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  AUM
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-800">
                    {hit.capitalManaged === 0 ? "N/A" : hit.currency + hit.capitalManaged}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  MIN INVESTMENT
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-800">
                    {hit.minInvestment === 0 ? "N/A" : hit.currency + hit.minInvestment}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div className="border-t border-gray-200 md:border-0 md:border-l">
            <div className="px-4 py-5 sm:p-6">
              <dl>
                <dt className="text-sm leading-6 font-normal text-gray-500">
                  MAX INVESTMENT
                </dt>
                <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                  <div className="flex items-baseline text-2xl leading-8 font-semibold text-gray-800">
                    {hit.maxInvestment === 0 ? "N/A" : hit.currency + hit.maxInvestment}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>*/}

			<div className="px-4 py-5 sm:px-6">
		    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Email address
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		          <p>{hit["Email Address"]}</p>
		        </dd>
		      </div>
		      <div className="sm:col-span-1">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Website
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		          <p>{hit["Company Website"]}</p>
		        </dd>
		      </div>
		    </dl>
		  </div>

		  <div className="px-4 py-2 mb-2 sm:px-6">
		    <dl className="grid grid-cols-1 col-gap-4 row-gap-6 sm:grid-cols-2">
		      <div className="sm:col-span-2">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Preferences
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		        	{hit["Keywords"]}
	        	</dd>
		      </div>
		    </dl>
		  </div>
		  <div className="px-4 py-2 mb-2 sm:px-6">
		    <dl className="grid grid-cols-1 col-gap-4 row-gap-6 sm:grid-cols-2">
		      <div className="sm:col-span-2">
		        <dt className="text-sm leading-5 font-medium text-gray-500">
		          Bio
		        </dt>
		        <dd className="mt-1 text-sm leading-5 text-gray-900">
		        	{hit["Bio"]}
	        	</dd>
		      </div>
		    </dl>
		  </div>
		</div>
	);
}
