import React from 'react';
import { RefinementList } from 'react-instantsearch-dom';

const InvestorFilters = ({ locked }) => (
  <div className="border border-gray-200 bg-white rounded-lg shadow-sm">
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
        </svg>
        <span>Keywords</span>
      </div>
      <RefinementList
        attribute="Keywords"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
        </svg>
        <span>Business Type</span>
      </div>
      <RefinementList
        attribute="Business Type"
        className="text-sm"
      />
    </div> 
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
        </svg>
        <span>Industry Type</span>
      </div>
      <RefinementList
        attribute="Industry Type"
        className="text-sm"
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z" clipRule="evenodd" />
        </svg>
        <span>Firm</span>
      </div>
      <RefinementList
        attribute="Company Name"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z" clipRule="evenodd" />
          <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
        </svg>
        <span>Job title</span>
      </div>
      <RefinementList
        attribute="Job Title"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
        <span>City</span>
      </div>
      <RefinementList
        attribute="City"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
        <span>State</span>
      </div>
      <RefinementList
        attribute="State"
        className="text-sm"
        showMore
      />
    </div>
    <div className="heaven-search-filter-section">
      <div className="text-base px-4 pt-4 pb-2 font-semibold flex">
        <svg className="h-5 w-5 opacity-50 text-gray-600 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028.675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z" clipRule="evenodd" />
        </svg>
        <span>Country</span>
      </div>
      <RefinementList
        attribute="Country"
        className="text-sm"
        showMore
      />
    </div>
  </div>
);

export default InvestorFilters;
