import React from 'react';
import Hero from './hero';
import Features from './features';
import HowItWorks from './howItWorks';
import WhatYouGet from './whatYouGet';
import Faq from './faq';
import Testimonials from './testimonials';
import Footer from './footer';
import { Helmet } from "react-helmet";

const Landing = (props) => (
  <div>
    <Helmet>
			<title>Connect with Investors, Influencers &amp; Users | Crowdcreate</title>
      <meta name="description" content="The fastest way to find and connect with the right people to help grow your business. We operate one of the largest proprietary databases of active investors, influencers, and users." />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://crowdcreate.us/" />
			<meta property="og:site_name" content="Crowdcreate" />
			<meta property="og:title" content="Connect with Investors, Influencers &amp; Users | Crowdcreate" />
			<meta property="og:description" content="The fastest way to find and connect with the right people to help grow your business. We operate one of the largest proprietary databases of active investors, influencers, and users." />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content="Connect with Investors, Influencers &amp; Users | Crowdcreate" />
			<meta name="twitter:description" content="The fastest way to find and connect with the right people to help grow your business. We operate one of the largest proprietary databases of active investors, influencers, and users." />
			<meta name="twitter:site" content="@crowdcreate_us" />
			<meta name="twitter:creator" content="@crowdcreate_us" />
    </Helmet>
    <Hero props={props} />
    <Features />
    <HowItWorks />
    <WhatYouGet />
    <Testimonials />
	  <Faq />
    <Footer />
  </div>
)


export default Landing;
