import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import Firebase from 'firebase/app';
import { NavLink } from 'react-router-dom';

import createUser from '../../actions/createUser';
import subscribeUser from '../../actions/subscribeUser';
import createDefaultList from '../../actions/createDefaultList';

import Logo from '../../assets/crowdcreate-logo.svg';

export default function AppsumoSignup(props) {

  const { register, errors, handleSubmit } = useForm();
  const [error, setError] = useState("");
  const docRef = Firebase.firestore().collection("redemptions").doc("codes");

  const checkRedemption = (data) => {

    docRef.get().then(function(doc) {
      var code = data.redemptionCode;
      var codeB = data.redemptionCodeTwo;
      var allCodes = doc.data().allCodes;
      var redeemedCodes = doc.data().redeemedCodes;

      if (code && !codeB) {
        if (allCodes.includes(code)) {
          if (redeemedCodes.includes(code)) {
            console.log("Code already redeemed");
            setError({message: "Code already redeemed"});
          } else {
            let plan = 2;
            signUp(data, plan, code, codeB, redeemedCodes);
          };
        } else {
          console.log("Your code is invalid");
          setError({message: "Your code is invalid"});        
        }
      } else if (code && codeB && (code !== codeB)) {
        if (allCodes.includes(code) && allCodes.includes(codeB)) {
          if (redeemedCodes.includes(code) || redeemedCodes.includes(codeB)) {
            console.log("Code already redeemed");
            setError({message: "Code already redeemed"});
          } else {
            let plan = 4;
            signUp(data, plan, code, codeB, redeemedCodes);
          };
        } else {
          console.log("Your code is invalid");
          setError({message: "Your code is invalid"}); 
        };
      } else if (code === codeB) {
        console.log("Your second code is invalid");
        setError({message: "Your second code is invalid"}); 
      } else {       
        console.log("Something went wrong");
        setError({message: "Something went wrong"});  
      };
    });
  }

//Use the sasme createUser path but createSub also
  const signUp = (data, plan, code, codeB, redeemedCodes) => {
    Firebase.auth().createUserWithEmailAndPassword(data.email, data.passwordOne)
      .then( () => {
        var authUser = Firebase.auth().currentUser;
        console.log(`signed up as ${authUser.email}`);
        console.log(authUser.uid);
        if (authUser) {
          return Promise.all([
            createUser(authUser),
            subscribeUser(authUser, plan),
            createDefaultList(authUser, plan),
            redeemCode(code, redeemedCodes),
            redeemCode(codeB, redeemedCodes)
          ])
            .then( () => {
              props.history.push("/dashboard");
            })
            .catch( error => {
              setError(error)
            });
        }
      })
      .catch( error => {
        setError(error)
      });
  }

  const redeemCode = (code, redeemedCodes) => {
    if (code) {
      redeemedCodes.push(code);
      docRef.update({'redeemedCodes': redeemedCodes});
    } else {
      console.log("optional code");
    }
  }

  const onSubmit = data => {
    if (data.passwordOne === data.passwordTwo) {
      checkRedemption(data);
    } else {
      setError({message: "Your passwords don't match!"});
    };
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto text-center sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <NavLink to="/">
            <img className="mx-auto h-12 w-auto px-4" src={Logo} alt="InvestorScout" />
          </NavLink>
        </div>
        <p className="mt-2 text-sm leading-5 text-gray-600 max-w">
          <span>Please fill out the form below to get started right away</span>          
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
                Email address
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  name="email"
                  id="email"
                  type="email"
                  ref={register({ 
                    required: "This input is required.",
                    minLength: {
                      value: 11,
                      message: "This input must exceed 10 characters"
                    },
                    maxLength: {
                      value: 129,
                      message: "This input exceeds 128 characters"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />   
              </div>
              {errors.email && <p className="mt-2 text-sm text-red-600" id="email-error">{errors.email.message}</p>}
            </div>

            <div className="mt-6">
              <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                Create password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  name="passwordOne"
                  id="passwordOne"
                  type="password"
                  ref={register({ 
                    required: "This input is required.",
                    maxLength: {
                      value: 129,
                      message: "This input exceeds 128 characters"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.passwordOne && <p className="mt-2 text-sm text-red-600" id="password-error">{errors.passwordOne.message}</p>}
            </div>

            <div className="mt-6">
              <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                Confirm password
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  name="passwordTwo"
                  id="passwordTwo"
                  type="password"
                  ref={register({ 
                    required: "This input is required.",
                    maxLength: {
                      value: 129,
                      message: "This input exceeds 128 characters"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.passwordTwo && <p className="mt-2 text-sm text-red-600" id="password-error">{errors.passwordTwo.message}</p>}
            </div>

            <div className="mt-6">
              <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                redemption code
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  name="redemptionCode"
                  id="redemptionCode"
                  type="text"
                  ref={register({ 
                    required: "This input is required.",
                    maxLength: {
                      value: 8,
                      message: "Your code is invalid"
                    },
                    minLength: {
                      value: 8,
                      message: "Your code is invalid"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.redemptionCode && <p className="mt-2 text-sm text-red-600" id="redemption-error">{errors.redemptionCode.message}</p>}
            </div>
            <div className="mt-6">
              <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                2nd redemption code (optional)
              </label>
              <div className="mt-1 rounded-md shadow-sm">
                <input
                  name="redemptionCodeTwo"
                  id="redemptionCodeTwo"
                  type="text"
                  ref={register({ 
                    maxLength: {
                      value: 8,
                      message: "Your code is invalid, please check again"
                    },
                    minLength: {
                      value: 8,
                      message: "Your code is invalid, please check again"
                    }
                  })}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                />
              </div>
              {errors.redemptionCodeTwo && <p className="mt-2 text-sm text-red-600" id="password-error">{errors.redemptionCodeTwo.message}</p>}
            </div>

            <div className="mt-6">
              <span className="block w-full rounded-md shadow-sm">
                <button type="submit" className="w-full flex justify-center py-3 px-5 border border-transparent text-base font-bold rounded-lg text-white bg-gray-800 hover:opacity-75 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                  Get started now
                  <svg className="fill-current opacity-25 w-6 h-6 ml-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
                  </svg>
                </button>
              </span>
              {error && <p className="mt-2 text-sm text-red-600" id="error">{error.message}</p>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
