import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Firebase from 'firebase/app';
import Transition from '../../styles/Transition.js';

class AddToListButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saved: false,
      addToListDropdownOn: false,
      fundIndex: [],
      investorIndex: [],
      profile: this.props.profile,
      plan: this.props.plan,
      status: this.props.status,
      lists: this.props.lists,
      dataType: this.props.dataType,
      error: false,
      errorMessage: "",
    };
    this.toggleAddToListDropdown = this.toggleAddToListDropdown.bind(this);
  }

  toggleAddToListDropdown() {
    this.setState(prevState => ({
      addToListDropdownOn: !prevState.addToListDropdownOn,
    }));
  }

  async addInvestorToList(key, profile, plan) {
    console.log("Adding investor");

    var docRef = Firebase.firestore().collection("lists").doc(key);
    await docRef.get().then(doc => {
      this.setState({
        investorIndex: doc.data().investorIndex || [],
      })
    })
    await docRef.collection("entries").get().then(snap => {
      if (snap.size > 2000) {
        return alert("You've reached limit. Please contact support!");
      }
      //if record already exists in the index, return alert
      if (profile.objectID && this.state.investorIndex.includes(profile.objectID)) {
        this.setState({
          error: true,
          errorMessage: "Duplicate records",
          addToListDropdownOn: false,
        })
        setTimeout(() => {
          this.setState({ error: false, errorMessage:"", });
        }, 1500);
        return;
      }
      if (snap.size < 201 || plan  === 4 || plan === 40) {
        profile["counter"] = snap.size + 1;
        profile["dataType"] = "Investor";
        profile["nameToOrderBy"] = profile["Last Name"] ? profile["Last Name"] : profile["First Name"];
        Firebase.firestore.Timestamp.now();
        docRef.collection("entries")
          .add(profile)
          .then( () => {
            var investorIndex = this.state.investorIndex;
            investorIndex.push(profile.objectID)
            docRef.update({
              investorIndex,
              updatedOn: Firebase.firestore.Timestamp.now()
            })
          })
          .catch( error => {
            alert(`Whoops, couldn't add the record: ${error.message}`)
          });
        this.setState({ saved: true, addToListDropdownOn: false });
        setTimeout(() => {
          this.setState({ saved: false });
        }, 1500);
      }
      else
        return alert("You reached your limit of 200 entries!");
    });
  }

  // async addFundToList(key, profile, plan) {
  //   console.log("Adding fund");

  //   var docRef = Firebase.firestore().collection("lists").doc(key);
  //   await docRef.get().then(doc => {
  //     this.setState({
  //       fundIndex: doc.data().fundIndex || [],
  //     })
  //   })
  //   await docRef.collection("entries").get().then(snap => {
  //     if (snap.size > 2000) {
  //       return alert("You've reached limit. Please contact support!");
  //     }
  //     //if record already exists in the index, return alert
  //     if (profile.objectID && this.state.fundIndex.includes(profile.objectID)) {
  //       this.setState({
  //         error: true,
  //         errorMessage: "Duplicate records",
  //         addToListDropdownOn: false,
  //       })
  //       setTimeout(() => {
  //         this.setState({ error: false, errorMessage:"", });
  //       }, 1500);
  //       return;
  //     }
  //     if (snap.size < 201 || plan  === 4 || plan === 40) {
  //       profile["counter"] = snap.size + 1;
  //       profile["dataType"] = "Fund";
  //       profile["nameToOrderBy"] = profile.officeFirmName || profile.name;
  //       profile["addedOn"] = Firebase.firestore.Timestamp.now();
  //       docRef.collection("entries")
  //         .add(profile)
  //         .then( () => {
  //           var fundIndex = this.state.fundIndex;
  //           fundIndex.push(profile.objectID)
  //           docRef.update({
  //             fundIndex,
  //             updatedOn: Firebase.firestore.Timestamp.now()
  //           })
  //         })
  //         .catch( error => {
  //           alert(`Whoops, couldn't add the record: ${error.message}`)
  //         });
  //       this.setState({ saved: true, addToListDropdownOn: false });
  //       setTimeout(() => {
  //         this.setState({ saved: false });
  //       }, 1500);
  //     }
  //     else
  //       return alert("You reached your limit of 200 entries!");
  //   });
  // }

  render() {
    const { profile, plan, status, dataType, addToListDropdownOn, saved, error } = this.state

    return (
      <div>
        <div className="ml-4 mt-4 flex-shrink-0">
          { (status !== "active" || plan === 1 || plan === 10) ? (
            <NavLink
              to="/pricing"
              type="button"
              className="ml-3 text-sm bg-gray-800 hover:opacity-75 text-white font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center"
            >
              <svg className="opacity-50 mr-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
              </svg>
              Save
              <svg className="opacity-50 ml-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" />
              </svg>
            </NavLink>
          ) : (
            <button
              type="button"
              onClick={() => this.toggleAddToListDropdown(!addToListDropdownOn)}
              className={`${saved ? 'bg-green-500 hover:bg-green-400 cursor-wait' : error ? 'bg-red-500 hover:bg-red-400 cursor-wait' : 'ml-3 text-sm bg-gray-800 hover:opacity-75 text-white font-semibold py-2 px-3 border border-gray-200 rounded-lg shadow-sm transition ease-in-out duration-150 inline-flex items-center'} relative inline-flex items-center px-3 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none`}
            >
              { saved ? (
                <div><i className="fas fa-check-circle heaven-nav-button-icon" />Saved!</div>
              ) : error ? (
                <div>Duplicate record</div>
              ) : (
                <div className="flex text-bold">
                  <svg className="opacity-50 mr-2 h-5 w-5 flex" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
                  </svg>
                  Save
                  <svg className="opacity-50 ml-2 h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                </div>
              ) }
            </button>
          )}
        </div>
        <Transition
          show={addToListDropdownOn}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className={`${addToListDropdownOn ? 'block' : 'hidden'} border border-gray-200 bg-white block origin-top-right z-10 absolute mt-2 ml-4 w-auto rounded-md shadow-lg`}>
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              {this.props.lists.map((list, index) => (
                <div
                  key={index}
                  onClick={() => this.addInvestorToList(list.id, profile, plan)}
                  className="flex block cursor-pointer px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  <svg className="fill-current opacity-50 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
                  </svg>
                  {list.name}
                </div>
              ))}
            </div>
          </div>
        </Transition>
      </div>
    )
  }
}

export default AddToListButton;
