import React from 'react';
import { NavLink } from 'react-router-dom';

import FirebaseUser from '../misc/FirebaseUser';
import Error from '../misc/Error';
import StripeCheckout from '../subscriptions/StripeCheckout';
import StripeBillingPortal from '../subscriptions/StripeBillingPortal';

import LandingNav from './nav';
import Features from './features';
import HowItWorks from './howItWorks';
import WhatYouGet from './whatYouGet';
import Faq from './faq';
import Testimonials from './testimonials';
import Footer from './footer';

const Pricing = () => (
	<div className="bg-gray-900 min-h-screen">
		<div className="pt-6">
			<LandingNav />
		</div>
	  <div className="pt-12 px-4 sm:px-6 lg:px-64 lg:pt-20">
	    <div className="text-center">
	      <h2 className="text-lg leading-6 font-semibold text-gray-600 uppercase tracking-wider">
	        Pricing
	      </h2>
	      <p className="mt- text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
	        An affordable way to access the best investors in the world
	      </p>
	      <p className="mt-3 max-w-4xl mx-auto text-xl leading-7 text-gray-500 sm:mt-5 sm:text-2xl sm:leading-8 font-normal">
	        Crowdcreate helps startups of all sizes connect with elite investors. You can upgrade or cancel anytime.
	      </p>
	    </div>
	  </div>

	  <div className="mt-16 bg-white lg:mt-20 mb-10">
	    <div className="relative z-0">
	      <div className="absolute inset-0 h-5/6 bg-gray-900 lg:h-2/3"></div>
	      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
	        <div className="relative lg:grid lg:grid-cols-6">
	          <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
	            <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-lg lg:mr-8">
	              <div className="flex-1 flex flex-col">
	                <div className="bg-white px-6 py-10">
	                  <div>
	                    <h3 className="text-center text-2xl leading-8 font-medium text-gray-900" id="tier-hobby">
	                      Startup
	                    </h3>
	                    <div className="mt-4 flex items-center justify-center">
	                      <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
	                        <span className="mt-2 mr-2 text-4xl font-medium">
	                          $
	                        </span>
	                        <span className="font-extrabold">
	                          249
	                        </span>
	                      </span>
	                      <span className="text-xl leading-7 font-medium text-gray-600">
	                        /month
	                      </span>
	                    </div>
	                  </div>
	                </div>
	                <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-200 p-6 bg-gray-100 sm:p-10 lg:p-6 xl:p-10">
	                  <ul>
	                    <li className="flex items-start">
	                      <div className="flex-shrink-0">
													<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
	                      </div>
	                      <p className="ml-3 text-base leading-6 font-medium text-gray-600">
	                        Access to database of 37,000+ investors
	                      </p>
	                    </li>
	                    <li className="mt-4 flex items-start">
	                      <div className="flex-shrink-0">
													<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
	                      </div>
	                      <p className="ml-3 text-base leading-6 font-medium text-gray-600">
	                        Unlimited Searches
	                      </p>
	                    </li>
	                    <li className="mt-4 flex items-start">
	                      <div className="flex-shrink-0">
													<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
	                      </div>
	                      <p className="ml-3 text-base leading-6 font-medium text-gray-600">
	                        Search filters
	                      </p>
	                    </li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 font-medium text-gray-600">
													Email addresses
												</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 font-medium text-gray-600">
													Create 1 investor list at a time up to 200 contacts
												</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 font-medium text-gray-600">
													Export to CSV
												</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 font-medium text-gray-600">
													Phone numbers and office addresses
												</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 font-medium text-gray-600">
													Bulk select investors
												</p>
											</li>
	                  </ul>
	                  <div className="mt-8">
	                    <div className="rounded-lg shadow-md">
                      	<FirebaseUser>
                        { ({ isLoading, error, auth, stripeCustomerId, email, subscribed }) => {
                          if (isLoading)
                            return <button className="block w-full text-center rounded-lg border border-transparent bg-gray-800 px-6 py-3 text-base leading-6 font-bold text-white hover:opacity-75 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 shadow-sm" aria-describedby="tier-basic">Buy now!</button>
                          if (error)
                            return <Error error={error} />
                          if (!auth)
                            return <NavLink to="/signup" className="block w-full text-center rounded-lg border border-transparent bg-gray-800 px-6 py-3 text-base leading-6 font-bold text-white hover:opacity-75 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 shadow-sm" aria-describedby="tier-basic">Buy now!</NavLink>
                          if (subscribed)
                            return <StripeBillingPortal stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} pricing={true} />
                          return <StripeCheckout plan="startupMonthly" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                        }}
	                      </FirebaseUser>
	                    </div>
	                  </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-5 lg:row-start-1 lg:row-end-4">
	            <div className="relative z-10 rounded-lg shadow-xl">
	              <div className="pointer-events-none absolute inset-0 rounded-lg border-2 border-red-600"></div>
	              <div className="absolute inset-x-0 top-0 transform translate-y-px">
	                <div className="flex justify-center transform -translate-y-1/2">
	                  <span className="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
	                    Most popular
	                  </span>
	                </div>
	              </div>
	              <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
	                <div>
	                  <h3 className="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6" id="tier-startup">
	                    Premium
	                  </h3>
	                  <div className="mt-4 flex items-center justify-center">
	                    <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl">
	                      <span className="mt-2 mr-2 text-4xl font-medium">
	                        $
	                      </span>
	                      <span className="font-extrabold">
	                        499
	                      </span>
	                    </span>
	                    <span className="text-2xl leading-8 font-medium text-gray-600">
	                      /month
	                    </span>
	                  </div>
	                </div>
	              </div>
	              <div className="border-t-2 border-gray-200 rounded-b-lg pt-10 pb-8 px-6 bg-gray-100 sm:px-10 sm:py-10">
	                <ul>
										<li className="flex items-start">
											<div className="flex-shrink-0">
												<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
												</svg>
											</div>
											<p className="ml-3 text-base leading-6 font-medium text-gray-600">
												Everything in Startup plan
											</p>
										</li>
										<li className="mt-4 flex items-start">
											<div className="flex-shrink-0">
												<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
												</svg>
											</div>
											<p className="ml-3 text-base leading-6 font-medium text-gray-600">
												Email addresses, phone numbers, and office addresses
											</p>
										</li>
										<li className="mt-4 flex items-start">
											<div className="flex-shrink-0">
												<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
												</svg>
											</div>
											<p className="ml-3 text-base leading-6 font-medium text-gray-600">
												Create unlimited investor lists up to 200 contacts per list
											</p>
										</li>
										<li className="mt-4 flex items-start">
											<div className="flex-shrink-0">
												<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
												</svg>
											</div>
											<p className="ml-3 text-base leading-6 font-medium text-gray-600">
												Bulk select 50 investors at a time
											</p>
										</li>
										<li className="mt-4 flex items-start">
											<div className="flex-shrink-0">
												<svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
												</svg>
											</div>
											<p className="ml-3 text-base leading-6 font-medium text-gray-600">
												2,000 contacts per list
											</p>
										</li>
										<li className="mt-4 flex items-start">
											<div className="flex-shrink-0">
												<svg className="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
													<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
												</svg>
											</div>
											<p className="ml-3 text-base leading-6 font-medium text-gray-600">
												Bulk select up to 100 investors at a time
											</p>
										</li>
	                </ul>
	                <div className="mt-10">
	                  <div className="rounded-lg shadow-md">
                    	<FirebaseUser>
                      { ({ isLoading, error, auth, stripeCustomerId, email, subscribed }) => {
                        if (isLoading)
                          return <button className="block w-full text-center rounded-lg border border-transparent bg-gray-800 px-6 py-4 text-xl leading-6 font-bold text-white hover:opacity-75 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 shadow-sm" aria-describedby="tier-startup">Buy now!</button>
                        if (error)
                          return <Error error={error} />
                        if (!auth)
                          return <NavLink to="/signup" className="block w-full text-center rounded-lg border border-transparent bg-gray-800 px-6 py-4 text-xl leading-6 font-bold text-white hover:opacity-75 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 shadow-sm" aria-describedby="tier-startup">Buy now!</NavLink>
                        if (subscribed)
                          return <StripeBillingPortal stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} pricing={true} />
                        return <StripeCheckout plan="premiumMonthly" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} />
                      }}
                      </FirebaseUser>
	                  </div>
	                </div>
	              </div>
	            </div>
	          </div>
	          <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-5 lg:col-end-7 lg:row-start-2 lg:row-end-3">
	            <div className="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-lg lg:ml-8">
	              <div className="flex-1 flex flex-col">
	                <div className="bg-white px-6 py-10">
	                  <div>
	                    <h3 className="text-center text-2xl leading-8 font-medium text-gray-900" id="tier-premium">
	                      Pro
	                    </h3>
	                    <div className="mt-4 flex items-center justify-center">
	                      <span className="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
	                        <span className="mt-2 mr-2 text-4xl font-medium">
	                          $
	                        </span>
	                        <span className="font-extrabold">
	                          999
	                        </span>
	                      </span>
	                      <span className="text-xl leading-7 font-medium text-gray-600">
	                        /month
	                      </span>
	                    </div>
	                  </div>
	                </div>
	                <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-200 p-6 bg-gray-100 sm:p-10 lg:p-6 xl:p-10">
	                  <ul>
											<li className="flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 font-medium text-gray-600">
													Everything in Premium plan
												</p>
											</li>
											<li className="mt-4 flex items-start">
												<div className="flex-shrink-0">
													<svg className="h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
												</div>
												<p className="ml-3 text-base leading-6 font-medium text-gray-600">
													Create unlimited investor lists up to 2,000 contacts per list
												</p>
											</li>
	                  </ul>
	                  <div className="mt-8">
	                    <div className="rounded-lg shadow-md">
                      	<FirebaseUser>
                        { ({ isLoading, error, auth, stripeCustomerId, email, subscribed }) => {
                          if (isLoading)
                            return <button className="block w-full text-center rounded-lg border border-transparent bg-gray-800 px-6 py-3 text-base leading-6 font-bold text-white hover:opacity-75 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 shadow-sm" aria-describedby="tier-premium">Buy now!</button>
                          if (error)
                            return <Error error={error} />
                          if (!auth)
                            return <NavLink to="/signup" className="block w-full text-center rounded-lg border border-transparent bg-gray-800 px-6 py-3 text-base leading-6 font-bold text-white hover:opacity-75 focus:outline-none focus:shadow-outline transition ease-in-out duration-150 shadow-sm" aria-describedby="tier-premium">Buy now!</NavLink>
                          if (subscribed)
                            return <StripeBillingPortal stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid} pricing={true} />
                          return <StripeCheckout plan="proMonthly" stripeCustomerId={stripeCustomerId} email={email} uid={auth.uid}/>
                        }}
	                      </FirebaseUser>
	                    </div>
	                  </div>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>
		<Footer />
	</div>
);

export default Pricing;
