import Firebase from 'firebase/app';

const createUser = (authUser) => {
  return Firebase.firestore()
    .collection(`users`).doc(authUser.uid)
    .set({
      email: authUser.email,
      subscribed: false,
    })
    .catch( error => {
      alert(`Whoops, couldn't create user: ${error.message}`)
    })
}

export default createUser;
