import React from 'react';
import { NavLink } from 'react-router-dom';

const Features = () => (
  <section>
    <div className="py-12 bg-white">
      <div className="max-w-xl mx-auto px-4 sm:px-4 lg:max-w-screen-xl lg:px-8">
        <div className="lg:grid lg:grid-cols-3 lg:gap-8">
          <div className="shadow-sm rounded-lg p-4 border border-gray-200">
            <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-red-600 text-white">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-bold text-gray-900">The leading industry database</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Our proprietary investor and influencer database consists of thousands of contacts that have been hand researched by a team of expert analysts from around the world. We identify the most qualified prospects in your industry
              </p>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 shadow-sm rounded-lg p-4 border border-gray-200">
            <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-red-600 text-white">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-bold text-gray-900">Build targeted lists with our CRM</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                We make it easy for you and save your team hundreds of hours. Find contact information instantly. Add your favorite investors and influencers directly from your Crowdcreate dashboard into CSV spreadsheet format
              </p>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 shadow-sm rounded-lg p-4 border border-gray-200">
            <div className="flex items-center justify-center h-12 w-12 rounded-lg bg-red-600 text-white">
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-bold text-gray-900">Filter Investors & Influencers by Industry</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                From crypto and startups, to ecommerce and real estate, our industry specific lists identify the top movers and shakers by deal flow and reach. Use these contacts to get connected to the experts that grow your business
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white border-t border-b border-gray-200">
      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="grid grid-cols-6 gap-4">
          <div className="col-start-2 col-span-4 place-items-center">
            <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              The most powerful investor & influencer database with the case studies to prove it
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-7 text-gray-500">
              Crowdcreate’s database includes tens of thousands of investors who have funded billions of dollars and influencers who have been seen by billions of people from all over the world. It would take you hundreds of thousands of dollars and multiple years to replicate our proprietary database we’ve built with experts from around the world.
            </p>
            <div className="mt-8 sm:flex">
              <div className="rounded-lg shadow">
                <NavLink to="/signup" className="flex items-center justify-center px-4 py-2 border border-transparent font-bold leading-6 font-semibold rounded-lg text-white bg-gray-800 hover:text-white hover:bg-indigo-500 shadow-sm focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                  Get started for free
                  <svg className="fill-current opacity-50 w-5 h-5 ml-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z" clipRule="evenodd" />
                  </svg>
                </NavLink>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <NavLink to="/pricing" className="flex items-center justify-center px-4 py-2 text-base leading-6 font-bold rounded-lg text-gray-700 bg-white border border-gray-200 shadow-sm hover:text-gray-900 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out">
                  See pricing
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Features;
